<script>
    import ThePersonalAddressesCard from "@/ThePersonal/ThePersonalAddressesCard";
    import ThePersonalAddressesNew from "@/ThePersonal/ThePersonalAddressesNew";
    import ThePersonalSkeletonBox from "@/ThePersonal/ThePersonalSkeletonBox";

    export default {
        name: "ThePersonalAddresses",
        components: {
            ThePersonalSkeletonBox,
            ThePersonalAddressesCard,
            ThePersonalAddressesNew,
        },
        data() {
            return {
                addressesList: [],
                isLoadedAddresses: false,
            };
        },
        mounted() {
            if (!this.addressesList.length) {
                this.getUserAddresses();
            }
        },
        methods: {
            getUserAddresses() {
                this.$store.profileApi.getProfileUserAddresses().then((response) => {
                    this.addressesList = response;
                    this.isLoadedAddresses = true;
                });
            },
            addUserAddress(address) {
                this.$store.profileApi.addProfileUserAddress(address).then(({ success }) => {
                    if (success) {
                        this.getUserAddresses();
                    }
                });
            },
            updateUserAddress(address) {
                this.$store.profileApi.updateProfileUserAddress(address);
            },
            removeUserAddress(id) {
                this.$store.profileApi.removeProfileUserAddress(id).then(({ success }) => {
                    if (success) {
                        this.getUserAddresses();
                    }
                });
            },
            setDefaultUserAddress(id) {
                this.$store.profileApi.favoriteProfileUserAddress(id).then(() => {
                    this.addressesList.forEach((address) => (address.favorite = false));
                    this.addressesList.find((address) => address.id === id).favorite = true;
                });
            },
        },
    };
</script>

<template>
    <section class="the-personal-addresses">
        <div class="the-personal-addresses__inner" v-show="isLoadedAddresses">
            <div
                class="the-personal-addresses__card"
                v-for="(address, index) in addressesList"
                :key="index"
            >
                <the-personal-addresses-card
                    :address="address"
                    @on-update="updateUserAddress"
                    @on-delete="removeUserAddress"
                    @on-set-default="setDefaultUserAddress"
                ></the-personal-addresses-card>
            </div>
            <div class="the-personal-addresses__card">
                <the-personal-addresses-new
                    :is-first="!addressesList.length"
                    @on-save="addUserAddress"
                ></the-personal-addresses-new>
            </div>
        </div>
        <the-personal-skeleton-box
            v-show="!isLoadedAddresses"
            type="address"
            :list="2"
        ></the-personal-skeleton-box>
    </section>
</template>

<style lang="scss">
    .the-personal-addresses__inner {
        @include bp($bp-desktop-sm) {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }
    }

    .the-personal-addresses__card {
        margin-bottom: 10px;
        @include bp($bp-desktop-sm) {
            flex-basis: 50%;
            max-width: 50%;
            margin-bottom: 0;
            padding: 0 10px 20px;
        }
    }
</style>

<script>
    import ThePersonalButton from "@/ThePersonal/components/ui/ThePersonalButton";
    import ThePersonalIcon from "@/ThePersonal/components/ThePersonalIcon";
    import ThePersonalTooltip from "@/ThePersonal/components/ui/ThePersonalTooltip";

    export default {
        name: "ThePersonalAddressesCard",
        components: { ThePersonalTooltip, ThePersonalIcon, ThePersonalButton },
        props: {
            address: {
                type: Object,
            },
            isNew: Boolean,
        },
        data() {
            return {
                form: {
                    name: this.address?.name || "",
                    city: this.address?.city || "",
                    street: this.address?.street || "",
                    house: this.address?.house || "",
                    flat: this.address?.flat || "",
                    favorite: this.address?.favorite ? 1 : 0,
                },
                isEdited: false,
                isDefault: false,
                isVisibleCitiesList: false,
                foundCitiesList: [],
                hasError: {
                    name: false,
                    city: false,
                    street: false,
                    house: false,
                    flat: false,
                },
            };
        },
        computed: {
            addressType() {
                if (this.form.name.toLowerCase() === "дом") {
                    return "home";
                }
                if (["офис", "работа"].includes(this.form.name.toLowerCase())) {
                    return "office";
                }
                return "address";
            },
            saveButtonText() {
                return this.isNew ? "Сохранить новый адрес" : "Сохранить изменения";
            },
            isDisabledSaveButton() {
                const requiredValues = ["name", "city", "street", "house"];
                const isEmptyRequired = requiredValues.some(
                    (value) => !this.form[value].length
                );
                const hasErrors = Object.values(this.hasError).some((e) => e);
                return isEmptyRequired || hasErrors;
            },
        },
        watch: {
            "form.name"(val) {
                this.hasError.name = !/^[a-zA-Zа-яА-ЯёЁ\-,.\s\d\\/]+$/u.test(val);
            },
            "form.city"(val) {
                this.hasError.city = !/^[a-zA-Zа-яА-ЯёЁ\-,.\s\d\\/]+$/u.test(val);
            },
            "form.street"(val) {
                this.hasError.street = !/^[а-яА-ЯёЁ\-,.\s\d\\/]+$/u.test(val);
            },
            "form.house"(val) {
                this.hasError.house = !/^[а-яА-ЯёЁ\-,.\s\d\\/]+$/u.test(val);
            },
            "form.flat"(val) {
                this.hasError.flat =
                    val.length > 0 ? !/^[а-яА-ЯёЁ\-,.\s\d\\/]+$/u.test(val) : false;
            },
        },
        mounted() {
            if (this.isNew) {
                this.isEdited = true;
            }
        },
        methods: {
            getCitiesList() {
                this.$store.rootApi
                    .getListCity({
                        term: this.form.city,
                    })
                    .then((response) => {
                        this.isVisibleCitiesList = true;
                        this.foundCitiesList = response;
                    });
            },
            setAddressCity(name) {
                this.form.city = name;
                this.isVisibleCitiesList = false;
            },
            setEditState(state) {
                this.isEdited = state;
            },
            setDefaultAddress() {
                if (!this.address.favorite) {
                    this.$emit("on-set-default", this.address.id);
                    this.isDefault = true;
                }
            },
            changeAddress() {
                if (this.isNew) {
                    this.$emit("on-save", { ...this.form });
                    this.clearForm();
                } else {
                    this.$emit("on-update", { id: this.address.id, ...this.form });
                    this.setEditState(false);
                }
            },
            deleteAddress() {
                this.$emit("on-delete", this.address.id);
            },
            clearForm() {
                Object.keys(this.form).forEach((key) => {
                    key === "favorite" ? (this.form[key] = 0) : (this.form[key] = "");
                });
            },
        },
    };
</script>

<template>
    <div
        class="the-personal-addresses-card"
        :class="{ 'the-personal-addresses-card--shadow': !isNew }"
    >
        <button
            v-if="!isNew"
            class="the-personal-addresses-card__default outline"
            :class="{ active: address.favorite }"
            @click="setDefaultAddress"
        >
            <span class="the-personal-addresses-card__tooltip">
                <the-personal-tooltip
                    v-if="address.favorite"
                    width="190"
                    position-x="right"
                    position-y="bottom"
                >
                    Адрес выбран по умолчанию
                </the-personal-tooltip>
            </span>

            <the-personal-icon name="star" width="24" height="24"></the-personal-icon>
        </button>
        <div class="the-personal-addresses-card__head">
            <the-personal-icon v-show="!isEdited" :name="addressType"></the-personal-icon>
            <label class="the-personal-addresses-card__label">
                <input
                    type="text"
                    class="the-personal-addresses-card__input"
                    placeholder="Название"
                    v-model="form.name"
                    :readonly="!isEdited"
                    :tabindex="isEdited ? 0 : -1"
                />
            </label>
        </div>

        <div class="the-personal-addresses-card__form">
            <div class="the-personal-addresses-card__label">
                <input
                    type="text"
                    class="the-personal-addresses-card__input"
                    :class="{ 'is-error-state': hasError.city }"
                    placeholder="Регион, город"
                    v-model="form.city"
                    :readonly="!isEdited"
                    :tabindex="isEdited ? 0 : -1"
                    @keyup="getCitiesList"
                />
                <ul
                    class="the-personal-addresses-card__cities-list"
                    v-show="foundCitiesList.length && isVisibleCitiesList"
                >
                    <li
                        class="the-personal-addresses-card__cities-item"
                        v-for="(city, index) in foundCitiesList"
                        :key="index"
                        @click="setAddressCity(city.name)"
                    >
                        {{ city.name }}
                    </li>
                </ul>
            </div>
            <label class="the-personal-addresses-card__label">
                <input
                    type="text"
                    class="the-personal-addresses-card__input"
                    :class="{ 'is-error-state': hasError.street }"
                    placeholder="Улица"
                    v-model="form.street"
                    :readonly="!isEdited"
                    :tabindex="isEdited ? 0 : -1"
                />
            </label>
            <label class="the-personal-addresses-card__label">
                <input
                    type="text"
                    class="the-personal-addresses-card__input"
                    :class="{ 'is-error-state': hasError.house }"
                    placeholder="Дом, строение, корпус"
                    v-model="form.house"
                    :readonly="!isEdited"
                    :tabindex="isEdited ? 0 : -1"
                />
            </label>
            <label class="the-personal-addresses-card__label">
                <input
                    type="text"
                    class="the-personal-addresses-card__input"
                    :class="{ 'is-error-state': hasError.flat }"
                    placeholder="Квартира, офис"
                    v-model="form.flat"
                    :readonly="!isEdited"
                    :tabindex="isEdited ? 0 : -1"
                />
            </label>
            <button
                v-if="!isEdited"
                class="the-personal-addresses-card__edit outline"
                @click="setEditState(true)"
            >
                Редактировать
            </button>
            <the-personal-button
                v-else
                @click="changeAddress"
                :disabled="isDisabledSaveButton"
            >
                {{ saveButtonText }}
            </the-personal-button>
        </div>

        <button
            v-if="!isNew && !isEdited"
            class="the-personal-addresses-card__delete outline"
            @click="deleteAddress"
        >
            <the-personal-icon name="delete" width="24" height="24"></the-personal-icon>
        </button>
    </div>
</template>

<style lang="scss">
    .the-personal-addresses-card {
        position: relative;
        padding: 15px;
        border-radius: $personal-box-radius;
        @include bp($bp-desktop-sm) {
            padding: 20px;
        }
    }

    .the-personal-addresses-card--shadow {
        box-shadow: $personal-common-shadow-min;
        border: $personal-common-border-full;
        @include bp($bp-desktop-sm) {
            box-shadow: $personal-common-shadow-max;
        }
    }

    .the-personal-addresses-card__head {
        display: flex;
        padding-bottom: 10px;
        .the-personal-addresses-card__input {
            color: $personal-card-title-color;
        }
        svg {
            margin-right: 3px;
            width: 20px;
            height: 20px;
        }
    }

    .the-personal-addresses-card__tooltip {
        display: none;
    }

    .the-personal-addresses-card__default {
        position: absolute;
        top: 15px;
        right: 20px;
        width: 20px;
        height: 20px;
        &.active {
            svg.icon-star {
                fill: $personal-accent-color;
                stroke: $personal-accent-color;
            }
        }
        svg.icon-star {
            width: 20px;
            height: 20px;
            fill: #fff;
            stroke: rgba($personal-accent-color, 0.5);
            transition: fill 0.2s ease-in, stroke 0.2s ease-in;
        }
        @include bp($bp-desktop-sm) {
            &:hover {
                .the-personal-addresses-card__tooltip {
                    display: block;
                }
            }
        }
    }

    .the-personal-addresses-card__delete {
        position: absolute;
        bottom: 15px;
        right: 15px;
        width: 24px;
        height: 24px;
        svg.icon-delete {
            width: 24px;
            height: 24px;
        }
        @include bp($bp-desktop-sm) {
            bottom: 20px;
            right: 20px;
        }
    }

    .the-personal-addresses-card__form {
        padding-top: 10px;
        border-color: $personal-divider-fill;
        border-width: 1px 0 0 0;
        border-style: solid;
        border-image: var(--border-image-gradient);
        border-image-slice: 1;

        .the-personal-addresses-card__label {
            margin-bottom: 15px;
        }
    }

    .the-personal-addresses-card__label {
        position: relative;
        display: block;
    }

    .the-personal-addresses-card__input {
        width: 100%;
        padding: 0;
        color: #8f90a6;
        font-size: 16px;
        line-height: 22px;
        border: none;
        border-bottom: $personal-border-width solid transparent;
        transition: border-bottom-color 0.2s ease-in;
        &.is-error-state {
            border-bottom-color: $personal-danger-color;
        }
        &[readonly] {
            cursor: default;
        }
        &::placeholder {
            color: #c7c9d9;
        }
    }

    .the-personal-addresses-card__edit {
        color: $personal-active-color;
        font-size: 16px;
        line-height: 20px;
    }

    .the-personal-addresses-card__cities-list {
        position: absolute;
        z-index: 3;
        left: 0;
        width: 100%;
        max-height: 180px;
        background-color: #fff;
        border: 1px solid #f2f2f5;
        overflow: auto;
    }

    .the-personal-addresses-card__cities-item {
        padding: 10px 12px;
        color: #40404b;
        @include bp($bp-desktop-sm) {
            cursor: pointer;
            transition: background-color 0.2s ease-in;
            &:hover {
                background-color: #f2f2f5;
            }
        }
    }
</style>

<script>
    import TransitionContainer from "@/components/TransitionContainer";
    import ThePersonalAddressesCard from "@/ThePersonal/ThePersonalAddressesCard";

    export default {
        name: "ThePersonalAddressesNew",
        components: { ThePersonalAddressesCard, TransitionContainer },
        props: {
            isFirst: Boolean,
        },
        data() {
            return {
                isOpened: false,
            };
        },
        methods: {
            toggleOpenState() {
                this.isOpened = !this.isOpened;
            },
            saveNewAddress(address) {
                if (this.isFirst) {
                    address.favorite = 1;
                }
                this.$emit("on-save", address);
                this.toggleOpenState();
            },
        },
    };
</script>

<template>
    <div class="the-personal-addresses-new" :class="{ opened: isOpened }">
        <button
            class="the-personal-addresses-new__icon outline"
            :class="{ opened: isOpened }"
            @click="toggleOpenState"
        ></button>
        <transition-container :show="!isOpened">
            <div class="the-personal-addresses-new__head" @click="toggleOpenState">
                Добавить новый адрес
            </div>
        </transition-container>

        <div class="the-personal-addresses-new__form" :class="{ opened: isOpened }">
            <transition-container :show="isOpened">
                <the-personal-addresses-card
                    is-new
                    @on-save="saveNewAddress"
                ></the-personal-addresses-card>
            </transition-container>
        </div>
    </div>
</template>

<style lang="scss">
    .the-personal-addresses-new {
        position: relative;
        border-radius: $personal-box-radius--small;
        overflow: hidden;
        border: $personal-common-border-full;
        &.opened {
            box-shadow: $personal-common-shadow-min;
        }
        @include bp($bp-desktop-sm) {
            border-radius: $personal-box-radius;
            box-shadow: $personal-common-shadow-max;
        }
    }

    .the-personal-addresses-new__icon {
        position: absolute;
        z-index: 3;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        border: 2px solid #fff;
        border-radius: 50%;
        transition: border-color 0.2s ease-in;
        -webkit-tap-highlight-color: transparent;
        &.opened {
            border-color: $personal-active-color;
            &:before,
            &:after {
                background-color: $personal-active-color;
            }
            &:before {
                transform: rotate(90deg);
                opacity: 0;
            }
            &:after {
                transform: rotate(0);
            }
        }
        &:before,
        &:after {
            content: "";
            position: absolute;
            right: 4px;
            top: 7px;
            width: 8px;
            height: 2px;
            background-color: #fff;
            border-radius: 5px;
            transition: transform 0.2s ease-in, opacity 0.2s ease-in;
        }
        &:after {
            transform: rotate(-90deg) translateZ(0);
        }
        @include bp($bp-desktop-sm) {
            border-color: $personal-active-color;
            top: 20px;
            right: 20px;
            cursor: pointer;
            &:before,
            &:after {
                background-color: $personal-active-color;
            }
        }
    }

    .the-personal-addresses-new__head {
        padding: 12px 15px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        background-color: $personal-button-fill;
        user-select: none;
        overflow: hidden;
        @include bp($bp-desktop-sm) {
            padding: 22px 20px;
            color: $personal-active-color;
            font-size: 16px;
            text-align: left;
            background-color: #fff;
            cursor: pointer;
        }
    }
</style>
